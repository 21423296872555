import { ApiHttpClient } from "@/globals";
import ICharmItemKeyApiResponse from '@/models/items/raw/ICharmItemKeyApiResponse';
import { ensureRegion } from '@/util/Utils';
import TableProvider from './TableProvider';
import IItemDropEntry from "@/models/items/IItemDropEntry";
import IItemSearchResult from "@/models/items/IItemSearchResult";
import IExchangeType, { ICategoryType } from "@/models/items/IExchangeType";
import type { ITableRow, UiStringResolvedTableRow } from "@vincentzhang96/dv-dnt-table-interfaces";
import { IExchangeTableRow, IItemCategoryTableRow } from "@vincentzhang96/dv-dnt-table-interfaces/src/duck";

export enum CashFilter {
    ALL = -1,
    NON_CASH = 0,
    CASH = 1,
}

export enum ItemPart {
    Face = 0,
    Hair = 1,
    Helmet = 2,
    Body = 3,
    Leg = 4,
    Hand = 5,
    Foot = 6,
    Necklace = 7,
    Earring = 8,
    Ring = 9,
    Ring2 = 10,

    CashHelmet = 0,
    CashBody = 1,
    CashLeg = 2,
    CashHand = 3,
    CashFoot = 4,
    CashNecklace = 5,
    CashEarring = 6,
    CashRing = 7,
    CashRing2 = 8,
    CashWing = 9,
    CashTail = 10,
    CashFaceDeco = 11,
    CashFairy = 12,
}

export interface ISearchParams {
    name: string;
    ranks: number[];
    types: number[];
    parts: number[];
    cash: CashFilter;
    categories: number[];
    classes: number[];
    maxLevel: number;
    minLevel: number;
}

export function searchParamsToQueryObject(params: ISearchParams) {
    return {
        name: params.name,
        lmin: params.minLevel,
        lmax: params.maxLevel,
        cls: params.classes.join(','),
        g: params.ranks.join(','),
        c: params.categories.join(','),
        t: params.types.join(','),
        parts: params.parts.join(','),
        cash: params.cash === CashFilter.ALL ? undefined : params.cash,
    };
}

export interface IItemProvider {

    getItemCharmRequiredKeys(charmItemId: number, region?: string): Promise<[number, number][]>;

    getItemDrop(itemDropId: number, region?: string, depth?: number): Promise<IItemDropEntry[]>;
    
    search(params: ISearchParams, region?: string): Promise<IItemSearchResult>;
    
    getExchangeTypes(region?: string): Promise<IExchangeType[]>;
    
    getItemCategories(region?: string): Promise<ICategoryType[]>;
}

export const PROB_MAX = 1000000000;

class ItemProvider implements IItemProvider {

    public async getItemCharmRequiredKeys(charmItemId: number, region?: string): Promise<[number, number][]> {
        region = ensureRegion(region);

        const resp = await TableProvider.getTableRowsMatching<ICharmItemKeyApiResponse>('charmitemkeytable', '_CharmItemID', 'eq', charmItemId, region);

        if (resp.length > 0) {
            const data = resp[0];
            
            const keyer = (n: number) => `_Key${n}` as keyof ICharmItemKeyApiResponse;
            const keyCoster = (n: number) => `_Key${n}cost` as keyof ICharmItemKeyApiResponse;

            const ret: [number, number][] = [];
            for (let i = 1; i <= 5; ++i) {
                const keyId = Number(data[keyer(i)]);
                const keyCost = Number(data[keyCoster(i)]);
                if (keyId && keyCost) {
                    ret.push([keyId, keyCost]);
                }
            }

            return ret;
        } else {
            return [];
        }
    }
    
    public async getItemDrop(itemDropId: number, region?: string, depth: number = 0): Promise<IItemDropEntry[]> {
        if (depth >= 5) {
            return [];
        }

        region = ensureRegion(region);
        
        let resp = await TableProvider.getTableRow('virt.itemdroptable', itemDropId, region) as any;
        
        let sum = 0;
        
        const ret: IItemDropEntry[] = [];
        
        for (let i = 1; i <= 20; ++i) {
            const isGroupKey = `_IsGroup${i}`;
            const itemIndexKey = `_Item${i}Index`;
            const itemCountKey = `_Item${i}Info`;
            const itemProbKey = `_Item${i}Prob`;
            
            const isGroup = resp[isGroupKey] as boolean;
            const itemIndex = resp[itemIndexKey] as number;
            const itemCount = resp[itemCountKey] as number;
            const itemProb = resp[itemProbKey] as number;
            
            if (itemIndex <= 0 || itemProb <= 0 || (!isGroup && itemCount <= 0)) {
                continue;
            }
            
            let cutoff = sum;
            sum += itemProb;
            const probPercent = itemProb / PROB_MAX;
            const isOverProb = cutoff >= PROB_MAX ? true : undefined;
            
            if (isGroup) {
                try {
                    const sub = await this.getItemDrop(itemIndex, region, depth + 1);
                    sub.forEach((v) => {
                        v.rate *= isOverProb ? 0 : probPercent;
                        v.prob *= probPercent;
                        v.isOverProb = isOverProb;
                    });
                    
                    ret.push(...sub);
                } catch (e) {
                    ret.push({
                        count: itemCount,
                        cutoff,
                        itemId: -1,
                        rate: isOverProb ? 0 : probPercent,
                        prob: itemProb,
                        isOverProb,
                        depth,
                    });
                }
            } else {
                ret.push({
                    count: itemCount,
                    cutoff,
                    itemId: itemIndex,
                    rate: isOverProb ? 0 : probPercent,
                    prob: itemProb,
                    isOverProb,
                    depth,
                });
            }
        }
        
        return ret;
    }
    
    public async search(params: ISearchParams, region?: string): Promise<IItemSearchResult> {
        region = ensureRegion(region);
        
        const res = await ApiHttpClient.get<IItemSearchResult>(`server/${region}/items/search`, {
            params: {
                ...searchParamsToQueryObject(params),
                ex: 1,
            },
        });
        
        return res.data;
    }
    
    public async getExchangeTypes(region?: string): Promise<IExchangeType[]> {
        region = ensureRegion(region);
        
        const res = await TableProvider.getTableRowsOffset<UiStringResolvedTableRow<IExchangeTableRow, '_NameID'>>('exchange', undefined, region, {
            uiresolve: ['_NameID']
        });
        
        return res.map((v) => ({
            id: v._ExchangeType,
            label: v._NameID_txt || `Exchange ${v.id}`,
            category: v._GroupID,
            type: v._CategoryType,
        }));
    }
    
    public async getItemCategories(region?: string): Promise<ICategoryType[]> {
        region = ensureRegion(region);
        
        const res = await TableProvider.getTableRowsOffset<UiStringResolvedTableRow<IItemCategoryTableRow, '_NameID'>>('itemcategorytable', undefined, region, {
            uiresolve: ['_NameID']
        });
        
        return res.map((v) => ({
            id: v._ItemCategoryType,
            label: v._NameID_txt || `Category ${v.id}`,
        }));
    }
}

export default new ItemProvider() as IItemProvider;
